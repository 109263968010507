import React from "react";
import { Link } from "react-router-dom";
import img from "../home/assets/download.webp";

const blogData = [
  {
    id: 1,
    title: "Seizing the Day: Unraveling the Essence of Time is Money",
    subtitle: "Embark on a journey to comprehend the profound philosophy that time is money. Uncover the keys to efficient time management and learn how every tick of the clock can shape your path to success.",
    image: img,
    name: "Prashant",
    currentTime: "2024-06-01 10:00:00",
    additionalData: [
      {
        text: "In-depth analysis of time management strategies.",
        text2: "Historical perspectives on the value of time.",
      },
      {
        description1: "Practical tips for making the most of your time.",
        description2: "Case studies of successful time management.",
      },
    ],
  },
  {
    id: 2,
    title: "Maximizing Productivity with Smart Goals",
    subtitle: "Learn how setting smart goals can boost your productivity and help you achieve more in less time.",
    image: img,
    name: "Prashant",
    currentTime: "2024-06-15 14:30:00",
    additionalData: [
      {
        text: "Detailed guide to setting SMART goals.",
        text2: "Examples of effective goal-setting techniques.",
      },
      {
        description1: "Benefits of having clear, measurable goals.",
        description2: "Steps to implement SMART goals in your daily life.",
      },
    ],
  },
  {
    id: 3,
    title: "Innovative Strategies for Modern Businesses",
    subtitle: "Explore innovative strategies that modern businesses can adopt to stay ahead in the competitive market.",
    image: img,
    name: "Prashant",
    currentTime: "2024-06-20 09:15:00",
    additionalData: [
      {
        text: "Business strategies for the modern era.",
        text2: "Examples of successful business innovations.",
      },
      {
        description1: "Tips for implementing innovative strategies.",
        description2: "Case studies of companies that have thrived.",
      },
    ],
  },
  {
    id: 4,
    title: "The Future of Technology: Trends to Watch",
    subtitle: "Delve into the future of technology and discover the trends that are set to shape the world.",
    image: img,
    name: "Prashant",
    currentTime: "2024-06-25 11:45:00",
    additionalData: [
      {
        text: "Upcoming technology trends.",
        text2: "Impact of technology on different industries.",
      },
      {
        description1: "Predictions for the future of tech.",
        description2: "Innovations to watch out for.",
      },
    ],
  },
];

export const BlogHome = () => {
  const randomIndex = Math.floor(Math.random() * blogData.length);
  const randomBlog = blogData[randomIndex];
  const remainingBlogs = blogData.filter((_, index) => index !== randomIndex).slice(0, 3);

  return (
    <div className="home-blogs p-[5vw] my-12 flex flex-col items-center justify-center font-sans bg-[#FFF3DC]">
      {/* Random Blog Section */}
      <div className="random-blog flex items-center justify-between gap-12 mb-20">
        <div className="left-side w-1/2">
          <img src={randomBlog.image} alt="random-blog" className="w-full h-auto" />
        </div>
        <div className="right-side w-1/2 flex flex-col items-start gap-4">
          <p className="text-4xl leading-[3rem] text-black font-bold">
            {randomBlog.title}
          </p>
          <p className="text-xl font-normal text-black">
            {randomBlog.subtitle}
          </p>
          <p className="text-2xl font-bold">
            {randomBlog.name} - <span>{randomBlog.currentTime}</span>
          </p>
          <div className="flex gap-4">
            <Link to={`/blogs/${randomBlog.id}`}>
              <button className="bg-primary mt-4 px-4 py-4 rounded-xl text-xl shadow-md text-slate-800 font-semibold">
                Read More
              </button>
            </Link>
            <Link to="/blogs">
              <button className="bg-secondary mt-4 px-4 py-4 rounded-xl text-xl shadow-md text-slate-800 font-semibold">
                Watch More
              </button>
            </Link>
          </div>
        </div>
      </div>

      {/* Additional Blogs Section */}
      <div className="additional-blogs w-full flex  items-start justify-between ">
        {remainingBlogs.map((blog) => (
          <div key={blog.id} className="w-full flex flex-col items-start gap-8 mb-12">
            <div className="w-4/5">
              <img src={blog.image} alt="blog" className="w-full h-auto" />
            </div>
            <Link to={`/blogs/${blog.id}`} className="w-2/3 flex flex-col gap-4">
              <p className="text-xl text-black font-bold">{blog.title}</p>
              <p className="text-base font-normal text-black">{blog.subtitle}</p>
              <p className="text-base font-bold">{blog.name} - <span>{blog.currentTime}</span></p>
              </Link>
          </div>
        ))}
      </div>
      <Link to="/blogs">
          <button className="bg-primary mt-8 px-6 py-4 rounded-xl text-xl shadow-md text-slate-800 font-semibold">
            View More
          </button>
        </Link>
    </div>
  );
};

