import React,{useEffect, useRef} from "react";

import video from "./assets/video.mp4";

export const HomeSec8 = () => {
    const videoRef = useRef(null);

    useEffect(() => {
      const videoElement = videoRef.current;
      if (videoElement) {
        videoElement.play();
      }
    }, []);
  return (
    <div className='px-40 flex flex-col items-center justify-center gap-8'>
        <h1 className="text-6xl text-[#2d2d2d] font-bold">Still Confused?</h1>
        <p className="text-[#2d2d2d] text-lg text-center font-normal">Explore Elympiad through a captivating video that demystifies its concept. No more confusion – unravel the exciting world of Elympiad and discover a new realm of possibilities!</p>
        <video
            ref={videoRef}
            className="rounded-sm  h-[25rem]"
            loop
            autoPlay
            muted
          >
            <source src={video} type="video/mp4" />
          </video>
    </div>
  )
}
