import React from "react";
import { useParams } from "react-router-dom";
import img from "../home/assets/download.webp";
import { Navbar } from "../navbar/Navbar";
import { HomeSec14 } from "../home/HomeSec14";

const blogData = [
  {
    id: 1,
    title: "Seizing the Day: Unraveling the Essence of Time is Money",
    subtitle:
      "Embark on a journey to comprehend the profound philosophy that time is money. Uncover the keys to efficient time management and learn how every tick of the clock can shape your path to success.",
    image: img,
    name: "Prashant",
    currentTime: new Date().toLocaleString(),
    additionalData: [
      {
        text: "In-depth analysis of time management strategies.",
        text2: "Historical perspectives on the value of time.",
      },
      {
        description1: "Practical tips for making the most of your time.",
        description2: "Case studies of successful time management.",
      },
    ],
  },
  {
    id: 2,
    title: "Maximizing Productivity with Smart Goals",
    subtitle:
      "Learn how setting smart goals can boost your productivity and help you achieve more in less time.",
    image: img,
    name: "Prashant",
    currentTime: new Date().toLocaleString(),
    additionalData: [
      {
        text: "Detailed guide to setting SMART goals.",
        text2: "Examples of effective goal-setting techniques.",
      },
      {
        description1: "Benefits of having clear, measurable goals.",
        description2: "Steps to implement SMART goals in your daily life.",
      },
    ],
  },
  // Add more blog data as needed
];

export const BlogDetail = () => {
  const { id } = useParams();
  const blog = blogData.find((blog) => blog.id === parseInt(id));

  return (
    <>
    <Navbar />
      <div className="blog-detail-page p-[5vw] font-sans">
        {blog ? (
          <>
            <div className="flex flex-col items-center justify-center gap-8">
              <h1 className="text-4xl font-bold text-black mb-4">
                {blog.title}
              </h1>
              {/* <p className="text-xl text-black mb-4">{blog.subtitle}</p> */}
              <p className="text-2xl font-bold mb-4">
                By{blog.name} - <span>{blog.currentTime}</span>
              </p>
              <img src={blog.image} alt="blog" className="mb-8" />
            </div>
            <div className="additional-text flex flex-col items-center justify-center">
              {blog.additionalData.map((data, index) => (
                <div key={index} className="mb-4">
                  <p className="text-lg font-semibold">{data.text}</p>
                  <p className="text-base">{data.text2}</p>
                  <p className="text-lg font-semibold">{data.description1}</p>
                  <p className="text-base">{data.description2}</p>
                </div>
              ))}
            </div>
          </>
        ) : (
          <p className="text-xl text-black">Blog not found</p>
        )}
      </div>
      <HomeSec14 />
    </>
  );
};
