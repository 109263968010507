import React,{useEffect, useRef} from "react";
import line2 from "./assets/line2.svg";
import video from "./assets/video.mp4";

export const HomeSec4 = () => {

  const steps = [
    {
      number: 1,
      title: "Download",
      subtitle: "Seamless Start to Your Quiz Journey",
      description:
        "Get started by downloading Elympiad directly from our website or your preferred app store. A world of knowledge awaits you at the tap of a button!",
    },
    {
      number: 2,
      title: "Sign-Up",
      subtitle: "Your Gateway to Learning and Competing",
      description:
        "Register effortlessly using your phone number or email ID. Join our ever-growing community of quiz enthusiasts and knowledge seekers!",
    },
    {
      number: 3,
      title: "Read Trivia",
      subtitle: "Fuel Your Curiosity with Rich Trivia",
      description:
        "Dive into an extensive collection of study materials covering General Knowledge and General Studies. Enhance your understanding and prepare yourself for an exciting quiz experience.",
    },
    {
      number: 4,
      title: "Play Quiz",
      subtitle: "Challenge Yourself and Others",
      description:
        "'Step into the arena by purchasing your desired quiz. Test your knowledge and skills by answering questions derived from the trivia you've studied. It's more than just a quiz; it's a thrilling race of minds!",
    },
    {
      number: 5,
      title: "Win Rewards",
      subtitle: "Fast Knowledge, Fast Rewards",
      description:
        "Triumph with your quick thinking and fast fingers. Be the first to answer correctly and reap the rewards. Each correct and speedy response brings you closer to victory and exciting prizes!",
    },
  ];

  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.play();
    }
  }, []);

  return (
    <div className="m-20 ">
      <div className="flex flex-col items-center justify-center">
        <h1 className="text-6xl font-extrabold text-[#2d2d2d]">How it Works</h1>
        <img src={line2} alt="/" />
      </div>
      <div className="w-full py-12 flex items-center gap-6">
        <div className="left-side-video-container flex items-center justify-center w-1/2 h-[100vh] bg-[#e7a522ef]">
        <video
            ref={videoRef}
            className="w-[30vw] h-[90vh] rounded-sm"
            loop
            autoPlay
            muted
          >
            <source src={video} type="video/mp4" />
          </video>
        </div>
        <div className="w-1/2 right-side-list-items flex flex-col items-start gap-8 relative">
          {steps.map((step, index) => (
            <div key={index} className="flex items-center gap-8 relative">
              <div
                className={`w-32 h-32 rounded-3xl flex items-center justify-center border-4 border-[#e7a522ef] ${
                  index === 0 ? "bg-white" : "bg-gray-800"
                }`}
              >
                <p className="text-[#e7a522ef] text-7xl font-extrabold">
                  {step.number}
                </p>
              </div>
              <div className="w-3/4 flex flex-col items-start gap-1">
                <p className="text-[#e7a522ef] text-2xl font-semibold">
                  {step.title}
                </p>
                <p className="text-xl text-black font-bold capitalize">
                  {step.subtitle}
                </p>
                <p className="text-gray-600 text-xl font-normal">
                  {step.description}
                </p>
              </div>
              {index < steps.length - 1 && (
                <div className="absolute left-16 top-32 h-32 border-l-2 border-dashed border-[#e7a522ef] -z-10"></div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
