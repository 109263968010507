import React from 'react';
import { Navbar } from '../navbar/Navbar';
import { HomeSec14 } from '../home/HomeSec14';
import { Blog } from './Blog';

export const BlogComponent = () => {
  return (
    <div>
       <Navbar />
         <Blog />
       <HomeSec14 />
    </div>
  )
}
