import React, { useContext } from 'react';
import { LanguageContext } from '../Context/LanguageContext'; // Ensure this path is correct
import img1 from './assets/feature.svg';
import img2 from './assets/startupBihar.webp';
import img3 from './assets/theHindu.webp';
import img4 from './assets/timesofInd.webp';
import Marquee from "react-fast-marquee";

// Content for different languages
const content = {
  en: {
    featuredOn: "As Featured On"
  },
  hi: {
    featuredOn: "प्रमुखता प्राप्त हुआ"
  }
};

export const HomeSec2 = () => {
  const { language } = useContext(LanguageContext);

  return (
    <div className='home-sec2 flex flex-col items-center justify-center my-12'>
      <div className='flex flex-col items-center justify-center'>
        <h1 className='text-5xl font-bold'>{content[language].featuredOn}</h1>
        <img src={img1} alt='/' className='mt-[-1rem] w-full opacity-80 -z-10 ' />
      </div>
      <Marquee direction="left" pauseOnHover="stop" duration="1000">
          <div className='flex items-center gap-40 overflow-hidden'>
                <img src={img4} alt='image1' className='w-80 '/>
                <img src={img2} alt='image1' className='w-80 '/>
                <img src={img3} alt='image1' className='w-80 '/>
                <img src={img4} alt='image1' className='w-80 '/>
                <img src={img3} alt='image1' className='w-80 '/>
          </div>
      </Marquee>
    </div>
  );
};
