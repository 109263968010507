import logo from './logo.svg';
// import './App.css';
import { Navbar } from './components/navbar/Navbar';
import { HomeSec1 } from './components/home/HomeSec1';
import { HomeSec2 } from './components/home/HomeSec2';
import { HomeSec3 } from './components/home/HomeSec3';
import { HomeSec4 } from './components/home/HomeSec4';
import { HomeSec5 } from './components/home/HomeSec5';
import { HomeSec6 } from './components/home/HomeSec6';
import { HomeSec7 } from './components/home/HomeSec7';
import { HomeSec8 } from './components/home/HomeSec8';
import { HomeSec9 } from './components/home/HomeSec9';
import { HomeSec10 } from './components/home/HomeSec10';
import { HomeSec11 } from './components/home/HomeSec11';
import { HomeSec12 } from './components/home/HomeSec12';
import { HomeSec13 } from './components/home/HomeSec13';
import { HomeSec14 } from './components/home/HomeSec14';
import { BlogHome } from './components/Blog/BlogHome';

function App() {
  return (
    <div className="App">
      <Navbar />
      <HomeSec1 />
      <HomeSec2 />
      <HomeSec3 />
      <HomeSec4 />
      <HomeSec5 />
      <HomeSec6 />
      <HomeSec7 />
      <HomeSec8 />
      <HomeSec9 />
      <HomeSec10 />
      <HomeSec11 />
      <HomeSec12 />
      <BlogHome />
      <HomeSec13 />
      <HomeSec14 />
    </div>
  );
}

export default App;
