import React, { useContext } from "react";
import { LanguageContext } from "../Context/LanguageContext"; // Ensure this path is correct
import line from "./assets/line.svg";
import Marquee from "react-fast-marquee";
import hatImg from "./assets/hat graduate.webp";

// Content for different languages
const content = {
  en: {
    heading: "Elympiad: A Quiz Platform for Everyone!",
    areYou: "Are you a?",
    youWant: "Do You Want",
    toBeNext: "to be the next",
    elympian: "Elympian?",
    listItems: [
      "Government Exam Aspirant",
      "Housemaker Seeking Engagement",
      "School and College Student",
      "Knowledge Enthusiast",
      "Competition Lover",
    ],
  },
  hi: {
    heading: "एलिंपियाड: हर किसी के लिए एक क्विज प्लेटफ़ॉर्म!",
    areYou: "क्या आप हैं?",
    youWant: "क्या आप चाहते हैं",
    toBeNext: "अगले",
    elympian: "एलिंपियन बनना?",
    listItems: [
      "सरकारी परीक्षा अनुयायी",
      "घरेलू महिला नियोजन चाहने वाली",
      "स्कूल और कॉलेज छात्र",
      "ज्ञान उत्साही",
      "प्रतियोगिता प्रेमी",
    ],
  },
};

export const HomeSec3 = () => {
  const { language } = useContext(LanguageContext);

  return (
    <div className="home-sec3 p-[3vw] pr-1">
      <div className=" flex flex-col items-center justify-center">
        <h1 className="text-[#2d2d2d] text-5xl font-extrabold">
          {content[language].heading}
        </h1>
        <img src={line} alt="/" className="w-[30vw]" />
      </div>

      <div className="flex items-center px-20 my-16 gap-8">
        <div className="w-1/3 flex flex-col items-start gap-">
          <h1 className="text-6xl font-extrabold mb-8">{content[language].areYou}</h1>
          {content[language].listItems.map((item, index) => (
            <ul key={index} className=" flex  items-center gap-2">
              <p className="w-2 h-2 rounded-full bg-[#FCAC10]"></p>
              <li className="text-xl text-[#000] font-semibold">{item}</li>
            </ul>
          ))}
        </div>
        <div className="w-1/3">
          <img src={hatImg} alt="/" />
        </div>
        <div className="w-1/3 flex flex-col items-start ">
          <p className="text-[5rem] text-[#FCAC10] font-extrabold capitalize  -mb-4">
            {content[language].youWant}{" "}
            <span className="text-[3rem] text-[#FCAC10] font-extrabold">You</span>
          </p>
          <p className="text-[3.5rem] font-extrabold capitalize text-[#2d2d2d]  -mb-5">{content[language].toBeNext}</p>
          <p className="text-[4rem] text-[#FCAC10] font-extrabold  -mb-0">{content[language].elympian}</p>
        </div>
      </div>
    </div>
  );
};
