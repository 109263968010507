import React from 'react'
import icon from './assets/i3.svg';
import icon2 from './assets/i4.svg';
import mobile1 from './assets/mobile1.webp';
import mobile2 from './assets/mobile2.webp';

const features = [
  {
    title: "Anti-Cheat System",
    subtitle: "Fair Play, Always: Advanced Anti-Cheat Technology",
    description: "Play with confidence in an environment where integrity is paramount. Our robust anti-cheat system ensures a level playing field, where knowledge and quick thinking are the keys to success."
  },
  {
    title: "Government Exams Aligned Trivia",
    subtitle: "Aspirant's Advantage: GK/GS Content Tailored for Government Exams",
    description: "Leverage our specially curated trivia, aligned with the General Knowledge and General Studies questions of various government exams. It's a perfect blend of competitive preparation and engaging learning."
  },

  {
    title: "Global Chat",
    subtitle: "Connect and Converse: The World is Your Quiz Community",
    description: "Engage in real-time conversations with quiz enthusiasts from around the globe. Share insights, challenge perspectives, and build a community of like-minded learners and competitors."
  },

  {
    title: "Precision Timing",
    subtitle: "Every Fraction Counts: Precision Up to 1/8th of a Second",
    description: "Experience the thrill of competition with our ultra-precise timing system. We measure quiz responses to the eighth part of a second, ensuring accuracy and fairness in every challenge."
  },

  {
    title: " Modern UI",
    subtitle: "Sleek, Intuitive, and User-Friendly",
    description: "  Navigate with ease through our modern and streamlined user interface. Designed for seamless interaction, our UI enhances your quiz journey with style and simplicity."
  },
  
];

export const HomeSec6 = () => {
  return (
    <div className='features bg-[#FFF3DC] py-20'>
      <div className='relative flex items-center justify-center'>
        <p className='text-gray-800 text-6xl font-bold capitalize'>features</p>
        <img src={icon} alt='/' className='absolute left-[39vw] top-2' />
        <img src={icon2} alt='/' className='absolute right-[40vw] top-2' />
      </div>
      <div className='feature-container px-20 relative flex items-center gap-12'>
        <div className='background-color bg-yellow-500 w-full h-[30rem] absolute top-[5.8rem] left-0'></div>
        <div className='w-1/4 flex flex-col items-start gap-2 z-10 mt-8'>
          {features.slice(0,2).map((feature, index) => (
            <div key={index} className='bg-[#FFF3DC] shadow-lg opacity-85 rounded-3xl flex flex-col items-start gap-2 p-4'>
              <p className='font-bold text-black text-base'>{feature.title}</p>
              <p className='font-semibold text-sm text-black'>{feature.subtitle}</p>
              <p className='text-sm font-normal text-black'>{feature.description}</p>
            </div>
          ))}
        </div>
        <div className='w-1/2 flex items-center justify-center gap-8 z-10'>
          <img src={mobile1} alt='/mobile-1' className='w-1/2' />
          <img src={mobile2} alt='/mobile-1' className='w-1/2' />
        </div>
        <div className='w-1/4 flex flex-col items-start gap-2 z-10'>
          {features.slice(2,5).map((feature, index) => (
            <div key={index} className='bg-[#FFF3DC] shadow-lg opacity-85 rounded-3xl flex flex-col items-start gap-2 p-4'>
                <p className='font-bold text-black text-base'>{feature.title}</p>
              <p className='font-semibold text-sm text-black'>{feature.subtitle}</p>
              <p className='text-sm font-normal text-black'>{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
