import React from "react";
import icon from "./assets/i1.svg";
import icon1 from "./assets/i2.svg";
import hatImg from './assets/hat graduate.webp'

const features = [
    {
      title: "Earn While You Learn",
      description: "Our unique platform rewards your intelligence with real rewards. Dive into our quizzes, sharpen your intellect, and earn tangible incentives."
    },
    {
        title: "Real Exam Prep",
        description: "Content aligned with GK/GS for government exams prepares you for real-world challenges. Elympiad is an invaluable tool for aspirants seeking to ace competitive exams."
      },
      {
        title: "Collaborative Wisdom",
        description: "Engage in our Global Chat, share insights, and learn collectively. Elympiad fosters a community where knowledge sharing leads to collective growth."
      },
      {
        title: "Passive Learning, Active Gains",
        description: "Every interaction on Elympiad is an educational opportunity. From trivia browsing to observing quiz battles, you're constantly enhancing your knowledge."
      },
      {
        title: "Leaderboard Learning",
        description: "Stay motivated with our interactive leaderboard. It's more than a scorecard; it's a testament to your growing knowledge. Even in downtime, it encourages continuous learning and improvement."
      },

]

export const HomeSec9 = () => {
  return (
    <div className="educational-value my-20 p-20  bg-[#FFF3DC]">
      <div className="relative flex flex-col items-center justify-center">
        <p className=" text-gray-800 text-[4vw] font-bold">
          Educational Value: Learning Meets Earning
        </p>
        <p className="text-[#2d2d2d] text-2xl font-medium">
          Elevate Your Knowledge with Elympiad
        </p>
        <img src={icon} alt="icon" className="absolute left-[4vw] top-4" />
        <img src={icon1} alt="icon" className="absolute right-[4vw] top-14" />
      </div>
      <div className="flex flex-wrap items-center justify-center my-20 gap-10">
        <div className=" w-1/4 flex flex-col items-start gap-12 ">
           {features.slice(0,2).map((item, index) =>(
             <div key={index} className="earning-box pl-8 py-2 pr-4 flex flex-col items-start gap-2 rounded-2xl shadow-xl">
                <p className="text-xl text-gray-950 font-semibold">{item.title}</p>
                <p className="text-base text-gray-800 font-normal">{item.description}</p>
            </div>
           ))}
        </div>
        <div className="w-1/3">
           <img src={hatImg} alt='/hat-image' className="w-full" />
        </div>
        <div className=" w-1/4 flex flex-col items-start gap-12 ">
           {features.slice(2,4).map((item, index) =>(
             <div key={index} className="earning-box pl-8 py-2 pr-4 flex flex-col items-start gap-2 rounded-2xl shadow-xl">
                <p className="text-xl text-gray-950 font-semibold">{item.title}</p>
                <p className="text-base text-gray-800 font-normal">{item.description}</p>
            </div>
           ))}
        </div>
        <div className=" w-1/4 flex flex-col items-start">
           {features.slice(4,5).map((item, index) =>(
             <div key={index} className="earning-box pl-8 py-2 pr-4 flex flex-col items-start gap-2 rounded-2xl shadow-xl">
                <p className="text-xl text-gray-950 font-semibold">{item.title}</p>
                <p className="text-base text-gray-800 font-normal">{item.description}</p>
            </div>
           ))}
        </div>
      </div>
    </div>
  );
};
