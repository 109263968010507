import React, { useState } from "react";
import { Link } from "react-router-dom";
import img from "../home/assets/download.webp";

const blogData = [
  {
    id: 1,
    title: "Seizing the Day: Unraveling the Essence of Time is Money",
    subtitle:
      "Embark on a journey to comprehend the profound philosophy that time is money. Uncover the keys to efficient time management and learn how every tick of the clock can shape your path to success.",
    image: img,
    name: "Prashant",
    currentTime: new Date().toLocaleString(),
    additionalData: [
      {
        text: "In-depth analysis of time management strategies.",
        text2: "Historical perspectives on the value of time.",
      },
      {
        description1: "Practical tips for making the most of your time.",
        description2: "Case studies of successful time management.",
      },
    ],
  },
  {
    id: 2,
    title: "Maximizing Productivity with Smart Goals",
    subtitle: "Learn how setting smart goals can boost your productivity and help you achieve more in less time.",
    image: img,
    name: "Prashant",
    currentTime: new Date().toLocaleString(),
    additionalData: [
      {
        text: "Detailed guide to setting SMART goals.",
        text2: "Examples of effective goal-setting techniques.",
      },
      {
        description1: "Benefits of having clear, measurable goals.",
        description2: "Steps to implement SMART goals in your daily life.",
      },
    ],
  },
  {
    id: 3,
    title: "Maximizing Productivity with Smart Goals",
    subtitle: "Learn how setting smart goals can boost your productivity and help you achieve more in less time.",
    image: img,
    name: "Prashant",
    currentTime: new Date().toLocaleString(),
    additionalData: [
      {
        text: "Detailed guide to setting SMART goals.",
        text2: "Examples of effective goal-setting techniques.",
      },
      {
        description1: "Benefits of having clear, measurable goals.",
        description2: "Steps to implement SMART goals in your daily life.",
      },
    ],
  },
  {
    id: 4,
    title: "Maximizing Productivity with Smart Goals",
    subtitle: "Learn how setting smart goals can boost your productivity and help you achieve more in less time.",
    image: img,
    name: "Prashant",
    currentTime: new Date().toLocaleString(),
    additionalData: [
      {
        text: "Detailed guide to setting SMART goals.",
        text2: "Examples of effective goal-setting techniques.",
      },
      {
        description1: "Benefits of having clear, measurable goals.",
        description2: "Steps to implement SMART goals in your daily life.",
      },
    ],
  },
  {
    id: 5,
    title: "Maximizing Productivity with Smart Goals",
    subtitle: "Learn how setting smart goals can boost your productivity and help you achieve more in less time.",
    image: img,
    name: "Prashant",
    currentTime: new Date().toLocaleString(),
    additionalData: [
      {
        text: "Detailed guide to setting SMART goals.",
        text2: "Examples of effective goal-setting techniques.",
      },
      {
        description1: "Benefits of having clear, measurable goals.",
        description2: "Steps to implement SMART goals in your daily life.",
      },
    ],
  },
  {
    id: 6,
    title: "Maximizing Productivity with Smart Goals",
    subtitle: "Learn how setting smart goals can boost your productivity and help you achieve more in less time.",
    image: img,
    name: "Prashant",
    currentTime: new Date().toLocaleString(),
    additionalData: [
      {
        text: "Detailed guide to setting SMART goals.",
        text2: "Examples of effective goal-setting techniques.",
      },
      {
        description1: "Benefits of having clear, measurable goals.",
        description2: "Steps to implement SMART goals in your daily life.",
      },
    ],
  },
  // Add more blog data as needed
];

const flattenObject = (obj) => {
  let result = '';

  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      result += flattenObject(obj[key]); // Recursively flatten the object
    } else {
      result += obj[key] + ' ';
    }
  }

  return result;
};

export const Blog = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredBlogs = blogData.filter((blog) =>
    flattenObject(blog).toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="blog-page p-[5vw]  font-sans">
      <div className="blog-header flex items-center justify-center gap-12">
        <div className="left-side w-1/2 flex flex-col items-start gap-4">
          <p className="text-4xl leading-[3rem] text-black font-bold">
            {blogData[0].title}
          </p>
          <p className="text-xl font-normal text-black">
            {blogData[0].subtitle}
          </p>
          <p className="text-2xl font-bold">
            {blogData[0].name} - <span>{blogData[0].currentTime}</span>
          </p>
          <Link to={`/blogs/${blogData[0].id}`}>
            <button className="bg-primary mt-4 px-4 py-4 rounded-xl text-xl shadow-md text-slate-800 font-semibold">
              Read More
            </button>
          </Link>
        </div>
        <div className="right-side w-1/2">
          <img src={blogData[0].image} alt="blog" />
        </div>
      </div>
      <div className="w-full flex flex-col items-center justify-center gap-8 mt-20">
        <h1 className="text-[4vw] text-black font-bold">Featured Blogs</h1>
        <div className="w-full flex items-center justify-center">
          <input
            type="search"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-1/2 p-4 rounded-l-full border-[1px] border-black"
          />
          <button className="bg-yellow-500 p-4 rounded-r-full text-xl">
            🔎
          </button>
        </div>

        <div className="w-full blog-container flex flex-wrap items-center gap-12 justify-between">
          {filteredBlogs.map((blog) => (
            <Link to={`/blogs/${blog.id}`} key={blog.id} className="w-1/5 flex flex-col items-start gap-4">
              <img src={blog.image} alt="blog" />
              <p className="text-xl text-black font-bold">{blog.title}</p>
              <p className="text-base font-medium">
                {blog.name} - <span>{blog.currentTime}</span>
              </p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};
