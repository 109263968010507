import React, { useState, useEffect, useRef } from "react";
import v1 from "./assets/v1.svg";
import v2 from "./assets/v4.svg";
import v3 from "./assets/v3.svg";
import user1 from "./assets/user1.webp";
import user2 from "./assets/user2.webp";
import user3 from "./assets/user3.webp";
import user4 from "./assets/user4.webp";

const voices = [
  {
    comment:
      "Elympiad's intuitive design and engaging quizzes have revolutionized our classroom experience. Students are now more eager to participate and learn.",
    image: user1,
    name: "Pallavi jha",
    position: "commerce insights",
  },
  {
    comment:
      "As a student, I find Elympiad to be a fantastic tool for studying and revising topics. The quizzes are fun and challenging.",
    image: user2,
    name: "prashant ramjan",
    position: "commerce insights",
  },
  {
    comment:
      "Elympiad's comprehensive platform helps me stay updated with the latest educational trends and assessment techniques.",
    image: user3,
    name: "Rakesh kumar thakur",
    position: "director, lakshya human development trust",
  },
  {
    comment:
      "Using Elympiad, I've seen a noticeable improvement in my child's performance. The interactive quizzes make learning enjoyable.",
    image: user4,
    name: "Amiri lal yadav",

    position: "commerce insights",
  },
];

export const HomeSec10 = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const timeoutRef = useRef(null);

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % voices.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? voices.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(nextSlide, 8000); // Change slide every 8 seconds
    return () => resetTimeout();
  }, [currentIndex]);



  return (
    <div id="about"  className="testimonials my-20">
      <div className="relative flex items-center justify-center">
        <p className="text-[4vw] text-[#2d2d2d] font-bold">
          Voice of appreciation
        </p>
        <img src={v1} alt="/v1" className="absolute left-[28vw] top-4 w-8" />
        <img src={v2} alt="/v1" className="absolute right-[28.5vw] top-4 w-8" />
      </div>
      <div className="p-20 flex items-center justify-between">
        <p className="text-4xl text-[#2d2d2d] font-medium">
          Educators Perspective On Elympiad
        </p>
        <p className="w-1/2 h-2 bg-yellow-500 rounded-xl"></p>
      </div>
      <div className="px-20">
        <div className="relative voices-container flex items-start gap-8 border-b-2">

          <button
            className="prev-btn absolute -left-10 bottom-8 flex items-center justify-center bg-yellow-200 hover:bg-yellow-400 w-10 h-10 rounded-full cursor-pointer"
            onClick={prevSlide}
          >
            <span>←</span>
          </button>
          <div
            className="next-btn absolute -right-10 bottom-8 flex items-center justify-center bg-yellow-200 hover:bg-yellow-400 w-10 h-10 rounded-full cursor-pointer"
            onClick={nextSlide}
          >
            <span>→</span>
          </div>
          <div className="relative overflow-hidden w-full">
            <div
              className="flex transition-transform duration-500"
              style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
              {voices.map((voice, index) => (
                <div
                  key={index}
                  className="flex-none w-full flex  items-start gap-8 px-24"
                >
                  <div>
                    <img src={v3} alt="/" className="w-12" />
                  </div>
                  <div>
                    <p className="text-2xl">{voice.comment}</p>
                    <div className="flex items-center gap-8 my-8">
                      <img src={voice.image} alt="user" className="mt-4 w-20" />
                      <div className="flex flex-col items-start gap-2">
                        <h1 className="text-2xl text-black capitalize font-medium">{voice.name}</h1>
                        <p className="text-base font-normal capitalize text-gray-700">{voice.position}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="relative w-full h-[8px] bg-gray-200">
          <div
            className="absolute h-full bg-yellow-500 transition-all duration-500"
            style={{
              width: `${100 / (8 * voices.length)}%`,
              left: `${(currentIndex / voices.length) * 100}%`,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};
