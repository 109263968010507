import React from "react";
import download from "./assets/download-btn.svg";
import discord from "./assets/discord.svg";

const company = [
  "About us",
  "Contact us",
  "Privacy Policy",
  "refund policy",
  "terms and conditions",
  "community guidlines",
];

export const HomeSec14 = () => {
  return (
    <>
      <div className="footer-container bg-[#2d2d2d] flex items-start gap-4 px-[5vw] py-12 mt-20">
        <div className="w-[15vw] flex flex-col items-start gap-8">
          <h1 className="text-slate-100 lg:text-[2vw] font-medium border-b-[1px] border-yellow-300">
            Company
          </h1>
          <ul className="flex flex-col items-start gap-2">
            {company.map((item, index) => (
              <li
                key={index}
                className="text-[1.25vw] cursor-pointer hover:underline capitalize font-normal text-slate-100"
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
        <div className="w-[15vw] flex flex-col items-start gap-8">
          <h1 className="text-slate-100 lg:text-[2vw] font-medium border-b-[1px] border-yellow-300">
            Get the App
          </h1>
          <img src={download} alt="/" className="w-4/5" />
        </div>
        <div className="w-[15vw] flex flex-col items-start gap-4">
          <h1 className="text-slate-100 lg:text-[2vw] font-medium border-b-[1px] border-yellow-300">
            Contact us
          </h1>
          <p className="text-[1.25vw] font-normal text-slate-100">
            support@elympiad.in
          </p>
          <img
            src={discord}
            alt="/"
            className="w-4/5 border-[1px] border-slate-200 rounded-xl py-2 px-4"
          />
        </div>

        <div className="w-[15vw] flex flex-col items-start gap-4">
          <h1 className="text-slate-100 lg:text-[2vw] font-medium border-b-[1px] border-yellow-300">
            Follow us
          </h1>
          <div className="flex items-center gap-[1vw]">
            <div className="w-[2vw] h-[2vw] hover:scale-105 cursor-pointer bg-gray-50 rounded-full flex items-center justify-center">
              <img
                src="https://img.icons8.com/?size=100&id=118468&format=png&color=000000"
                alt="/"
                className="w-[1vw] h-[1vw]"
              />
            </div>
            <div className="w-[2vw] h-[2vw] hover:scale-105 cursor-pointer bg-slate-50 rounded-full flex items-center justify-center">
              <img
                src="https://img.icons8.com/?size=100&id=32292&format=png&color=000000"
                alt="/"
                className="w-[1vw] h-[1vw]"
              />
            </div>
          </div>
        </div>
        <div className="w-[30vw] flex flex-col items-start gap-4">
          <h1 className="text-slate-100 lg:text-[2vw] font-medium border-b-[1px] capitalize border-yellow-300">
            Newsletter
          </h1>
          <p className="text-[1.25vw] font-normal text-slate-100">
            Sign up for Elympiad emails to be first to see new updates, news and
            offers.
          </p>
          <div className="w-full relative">
            <input
              type="email"
              className=" w-full overflow-hidden px-4 h-16 border-none rounded-2xl bg-gray-50 text-lg"
              placeholder="Enter Your Email Address"
            />
            <button className="absolute shadow-md top-3 right-4 bg-yellow-500 rounded-xl px-6 py-2">
              <span className="uppercase text-gray-50 text-sm font-bold">sign up</span>
            </button>
          </div>
        </div>
      </div>
      <div className="copyright-text bg-[#2d2d2d] text-sm text-gray-50 py-8 flex items-center justify-center">
        <p>Copyright © Indic Arena. Trade Marks belongs to their respective owners. All rights reserved.</p>
      </div>
    </>
  );
};
