import React from 'react'
import downloadBtn from './assets/download-btn.svg'
import mobile from './assets/download.webp'

export const HomeSec13 = () => {
  return (
    <div className='download-container flex px-10 mx-20 gap-12 rounded-2xl pt-12'
    style={{background: "linear-gradient(0deg, #fcac10, #fcac10), radial-gradient(88.63% 231.32% at 11.37% 22.51%, #ffffff4d, #fff0)"}}
    >
        <div className='left flex flex-col items-center justify-center gap-8'>
             <p className='text-[3vw] text-black font-bold'>Earn Knowledge & Money with the Elympaid quiz App</p>
             <p className='text-xl text-gray-800 text-start'>Download elympiad now and turn knowledge into cash - your smart journey begins!</p>
             <img src={downloadBtn} alt='download-link' className='w-40'/>
        </div>
        <div className='right w-1/2'>
            <img src={mobile} alt='right-img' />
        </div>
    </div>
  )
}
