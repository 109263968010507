import React, { useContext, useState } from "react";
import { LanguageContext } from "../Context/LanguageContext"; 
import { Link } from "react-router-dom";
import logo from "./assets/logo.svg";

export const Navbar = () => {
  const { language, changeLanguage } = useContext(LanguageContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLanguageChange = (lang) => {
    changeLanguage(lang);
    setDropdownOpen(false);
  };

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="flex bg-primary items-center justify-between px-[10%] py-[2%] bg-amber-500">
      <div className="w-1/2">
        <img src={logo} alt="/" className="w-2/5" />
      </div>
      <div className="w-1/2 flex items-center justify-center gap-[2rem]">
        <Link to='/' className="text-xl font-bold cursor-pointer">
          {language === "en" ? "Home" : "Home"}
        </Link>
        <p className="text-xl cursor-pointer" onClick={() => scrollToSection("about")}>{language === "en" ? "About Us" : "About Us"}</p>
        <p className="text-xl cursor-pointer"  onClick={() => scrollToSection("faq-section")}>
          {language === "en" ? "FAQs" : "FAQs"}
        </p>
        <Link to='/blogs' className="text-xl cursor-pointer">{language === "en" ? "Blogs" : "Blogs"}</Link>
        <div className="relative ml-8">
          <button
            onClick={toggleDropdown}
            className=" w-[10rem] flex items-center justify-between bg-black px-5 py-2 text-gray-50 rounded-xl"
          >
            {language === "en" ? "English" : "हिंदी"}
            <svg
              className="w-8 h-5 ml-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </button>
          {dropdownOpen && (
            <div className="absolute right-0 top-4 mt-0 w-40 bg-white opacity-80 border-gray-200 rounded-md shadow-lg">
              <button
                onClick={() => handleLanguageChange("en")}
                className="block w-full text-left px-4 py-2 text-sm text-gray-900 hover:bg-gray-100"
              >
                English
              </button>
              <button
                onClick={() => handleLanguageChange("hi")}
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                हिंदी
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
