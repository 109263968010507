import React from "react";
import Marquee from "react-fast-marquee";
import icon from "./assets/i1.svg";
import icon1 from "./assets/i2.svg";
import pay1 from "./assets/insti1.png";
import pay2 from "./assets/insti2.png";
import pay3 from "./assets/insti3.png";
import pay4 from "./assets/insti4.png";
import pay5 from "./assets/insti5.webp";
import testi1 from './assets/testi1.png'
import testi2 from './assets/testi2.png'
import testi3 from './assets/testi3.png'
import testi4 from './assets/testi4.png'
import testi5 from './assets/testi5.png'

export const HomeSec7 = () => {
  return (
    <div className="py-40">
      <div className="relative flex items-center justify-center">
        <p className=" text-gray-800 text-6xl font-bold">
          Trusted By Students Of
        </p>
        <img src={icon} alt="icon" className="absolute left-[24rem] top-0" />
        <img src={icon1} alt="icon" className="absolute right-[24rem] top-10" />
      </div>

      <Marquee direction="left" speed={200} pauseOnHover="stop">
        <div className="flex items-center gap-[6rem] my-8 ml-[6rem]">
          <img src={pay1} alt="icon-1" className="w-[13vw]" />
          <img src={pay2} alt="icon-1" className="w-[13vw]" />
          <img src={pay4} alt="icon-1" className="w-[13vw]" />
          <img src={pay3} alt="icon-1" className="w-[13vw]" />
          <img src={pay5} alt="icon-1" className="w-[13vw]" />
        </div>
      </Marquee>
      <div className="w-full p-20 flex items-start gap-">
       <div className="w-1/2 flex flex-wrap items-start gap-2 space-y-5">
         <img src={testi1} alt='/' className="w-[18rem] h-[15rem]  shadow-xl rounded-2xl p-4 mt-5"/>
         <img src={testi2} alt='/' className="w-[18rem] h-[15rem]  shadow-xl rounded-2xl p-4"/>
         <img src={testi3} alt='/' className="w-[18rem] h-[15rem]  shadow-xl rounded-2xl p-4"/>
         <img src={testi4} alt='/' className="w-[18rem] h-[15rem]  shadow-xl rounded-2xl p-4"/>
       </div>
       <div className="w-1/4">
         <img src={testi5} alt='/' className="w-full h-[33rem] shadow-xl rounded-2xl"/>
       </div>
      </div>
    </div>
  );
};
