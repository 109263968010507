import React, { useContext } from "react";
import { LanguageContext } from "../Context/LanguageContext";
import "./HomeSec1.css";
import clip from "./assets/clip.svg";
import bandImg from "./assets/band-img.webp";
import rightImg from "./assets/right-image.webp";
import logo from "./assets/elympiad.svg";
import img1 from "./assets/img1.webp";
import img2 from "./assets/img2.webp";
import img3 from "./assets/img3.webp";

const content = {
  en: {
    heading1: "Quiz, Learn, Earn:",
    heading2: "Earn:",
    heading3: " Your Gateway to Success!",
    description:
      "Win real money while enhancing your knowledge with Elympiad - the ultimate quiz app for students!",
    banner: "#India's First Pool-Based Educational Quiz App",
    safePayments: "100% Safe Payments",
    support: "24x7 Support",
    withdrawal: "Instant Withdrawal",
  },
  hi: {
    heading1: "क्विज़, सीखें, ",

    heading2: "कमाएं:",
    heading3: "आपकी सफलता का द्वार!",
    description:
      "वास्तविक पैसे जीतें और अपनी ज्ञान बढ़ाएं Elympiad के साथ - छात्रों के लिए अंतिम क्विज़ ऐप!",
    banner: "#भारत का पहला पूल-आधारित शैक्षिक क्विज़ ऐप",
    safePayments: "100% सुरक्षित भुगतान",
    support: "24x7 समर्थन",
    withdrawal: "तत्काल निकासी",
  },
};

export const HomeSec1 = () => {
  const { language } = useContext(LanguageContext);

  return (
    <div className="">
      <div className="home-hero bg-primary flex items-center justify-between px-[10%]  pt-[2%]">
        <div className="home-hero-left w-3/5 flex flex-col items-start space-y-6">
          <h1 className="text-[3rem] font-bold ">
            {content[language].heading1}{" "}
            <span className="bg-white earn">{content[language].heading2}</span>
            <span className=" bg-primary block mt-1">{content[language].heading3}</span>
          </h1>
          {/* <h1 className="text-5xl font-bold">{content[language].heading2}</h1> */}
          <p className="text-xl text-white font-semibold">
            {content[language].description}
          </p>
          <div className="bg-white flex items-center w-fit px-4 py-2 rounded-lg">
            <img src={bandImg} alt="band-img" />
            <p className="text-black text-xl font-bold">
              {content[language].banner}
            </p>
          </div>
          <div className="flex flex-col items-center space-y-4 py-4">
            <img src={logo} alt="" />
            <div className="flex items-center gap-2">
              <div className="flex items-center bg-white rounded-md w-fit p-2">
                <img src={img1} alt="/" />
                <p className="text-base">{content[language].safePayments}</p>
              </div>
              <div className="flex items-center bg-white rounded-md w-fit p-2">
                <img src={img2} alt="/" />
                <p className="text-base">{content[language].support}</p>
              </div>
              <div className="flex items-center bg-white rounded-md w-fit p-2">
                <img src={img3} alt="/" />
                <p className="text-base">{content[language].withdrawal}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="home-hero-right w-2/5">
          <img src={rightImg} alt="/right-image" />
        </div>
      </div>
      <div>
        <img src={clip} alt="clipPath" />
      </div>
    </div>
  );
};
