import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import ScrollToTop from "./components/Utils/ScrollToTop";
import "./index.css";
import App from "./App";
import { LanguageProvider } from "./components/Context/LanguageContext";
import { BlogComponent } from "./components/Blog/BlogComponent";
import { BlogDetail } from "./components/Blog/BlogDetail";

const defaultRoutes = [
  { path: "/", element: <App /> },
  { path: "home", element: <App /> },
  { path: "blogs", element: <BlogComponent /> },
  { path: "blogs/:id", element: <BlogDetail /> },
];

const Initialize = () => {
  const element = useRoutes(defaultRoutes);
  return element;
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <LanguageProvider>
      <Router>
        <ScrollToTop /> {/* Add ScrollToTop here */}
        <Initialize />
      </Router>
    </LanguageProvider>
  </React.StrictMode>
);
