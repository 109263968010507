import React from 'react'
import icon from './assets/i1.svg';
import icon1 from './assets/i2.svg';
import Marquee from 'react-fast-marquee';
import pay1 from './assets/pay1.webp';
import pay2 from './assets/pay2.webp';
import pay3 from './assets/pay3.webp';
import pay4 from './assets/pay4.webp';
import pay5 from './assets/pay5.webp';
import pay6 from './assets/pay6.webp';
import pay7 from './assets/pay7.webp';


export const HomeSec5 = () => {
  return (
    <div className='payment-partners'>
        <div className='relative flex items-center justify-center'>
            <p className=' text-gray-800 text-6xl font-bold'>Our Payment Partners</p>
            <img src={icon} alt='icon' className='absolute left-[25rem] top-0'/>
            <img src={icon1} alt='icon' className='absolute right-[24.5rem] top-10'/>
        </div>
        <Marquee direction='left' speed={200} pauseOnHover="stop">
           <div className='flex items-center gap-[6rem] my-8 ml-[6rem]'> 
             <img src={pay2} alt='icon-1' className='w-[8vw]'/>
             <img src={pay3} alt='icon-1' className='w-[8vw]'/>
             <img src={pay4} alt='icon-1' className='w-[8vw]'/>
             <img src={pay5} alt='icon-1' className='w-[8vw]'/>
             <img src={pay1} alt='icon-1' className='w-[8vw]'/>
             <img src={pay6} alt='icon-1' className='w-[8vw]'/>
             <img src={pay7} alt='icon-1' className='w-[8vw]'/>
           </div>
        </Marquee>
    </div>
  )
}
