import React from 'react'
import star from './assets/star.svg'
import indicLogo from './assets/indic-logo.webp'
import line from './assets/product-line.svg'
import product1 from './assets/product1.webp'
import product2 from './assets/product2.webp'
import product3 from './assets/product3.webp'

export const HomeSec12 = () => {
  return (
    <div className='indic-product flex flex-col items-center justify-center gap-8 my-20'>
         <div className='relative'>
              <p className='text-5xl text-black font-bold'>A Product of</p>
              <img src={star} alt='star-img' className='w-8 absolute top-2 -right-10' />
              <img src={line} alt='star-img' className='w-48 absolute top-9 -z-10 right-10 ' />       
         </div>
         <div className='flex items-center justify-center'>
              <img src={indicLogo} alt='star-img' className='' />
         </div>
         <div className='flex items-center justify-center gap-8 py-10'>
           <img src={product1} alt='/product-1'className='w-80' />
           <img src={product2} alt='/product-1' className='w-80'/>
           <img src={product3} alt='/product-1'className='w-80' />
         </div>
         
    </div>
  )
}
