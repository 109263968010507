import React, { useState } from "react";

const faqsItems = [
  {
    title: "What is Elympaid?",
    subtitle:
      "Elympiad is an innovative learning and earning platform that allows users to participate in knowledge-based quizzes and earn rewards based on their academic prowess.",
  },
  {
    title: "Which type of quizzes can I join?",
    subtitle:
      "Elympiad offers a wide range of quizzes covering various subjects and topics, including current affairs, general knowledge, specific academic fields, and more. You can join any quiz that matches your interests and expertise.",
  },

  {
    title: "When do I get my winnings?",
    subtitle:
      "Points are calculated based on the accuracy and speed of your answers. The quicker and more accurate your response, the higher your points.",
  },
  {
    title: "How are Elympiad points calculated?",
    subtitle:
      "You can input a referral code, supplied by your friend, during the initial account creation. However, it can only be used once, and the discount will be deducted from your total entry fee before you proceed to payment of the quiz.",
  },
  {
    title: "When do I get my winnings?",
    subtitle:
      "Elympiad is an innovative learning and earning platform that allows users to participate in knowledge-based quizzes and earn rewards based on their academic prowess.",
  },
  {
    title: "How do I use an Elympiad referral code?",
    subtitle:
      "Elympiad is an innovative learning and earning platform that allows users to participate in knowledge-based quizzes and earn rewards based on their academic prowess.",
  },
  {
    title: "Is it safe to participate in Elympiad quizzes?",
    subtitle:
      "Absolutely! Elympiad is committed to providing a secure and fair environment for all participants. Our platform uses advanced security measures to ensure user data protection and fair play.",
  },
  {
    title: "How to Play on Elympiad?",
    subtitle:
      "To play on Elympiad, simply register or log in, choose a quiz from the available list, enter the quiz by paying the entry fee (if applicable), and start answering the questions. Your performance will determine your score and potential winnings.",
  },
  {
    title: "How should I download the Elympiad app?",
    subtitle:
      "The Elympiad app can be downloaded from the Google Play Store or Apple App Store. Just search for Elympiad and click on the install button to download.",
  },
];

export const HomeSec11 = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleExpand = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };

  return (
    <div id="faq-section" className="faqs my-60">
      <div className="flex flex-col items-center justify-center">
        <h1 className="text-[4vw] font-bold font-sans">FAQs</h1>
        {/* <img src={} alt='line-draw' /> */}
      </div>
      <div className="faqs-container flex flex-col items-start justify-center gap-8 py-10 px-40">
        {faqsItems.map((item, index) => (
          <div
            key={index}
            className="w-full flex flex-col items-start justify-between px-8 py-2 rounded-lg gap-4 border-[2px] border-yellow-400"
          >
            <div
              className="flex items-center justify-between w-full"
              onClick={() => toggleExpand(index)}
            >
              <div className="flex flex-col items-start px-2 py-2 rounded-lg gap-4">
                <p className="text-2xl text-gray-900 font-semibold">
                  {item.title}
                </p>
                {expandedIndex === index && (
                  <p className="text-xl text-gray-700 font-normal">
                    {item.subtitle}
                  </p>
                )}
              </div>
              <button
                onClick={() => toggleExpand(index)}
                className={`text-4xl font-bold text-black transform transition-transform duration-300 ${
                  expandedIndex === index ? "rotate-180" : ""
                }`}
              >
                ⌄
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
